<template>
  <div class="product-containter">
    <nav-menu></nav-menu>
    <div class="product-body">
      <div class="product-apply flex-a flex-b">
        <div class="product-left">
          <div>电子银行保函</div>
          <div>纯信用免保证金、额度高可循环、线上办、费率低、秒开立</div>
        </div>
        <div class="product-right">立即申请</div>
      </div>
      <div class="containter">
        <div><img src="./../assets/time.png" alt=""></div>
        <div class="title">申请成功，授信额度审批中</div>
        <div>最迟一个工作日出额度</div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
export default {
  name: 'approval',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.product-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .product-body {
    padding: 20px 0;

    .product-apply {
      box-sizing: border-box;
      width: 60%;
      margin: 0 auto 10px;
      background: url(./../assets/product_bg.jpg)no-repeat 0 0;
      background-size: 100%;
      color: #fff;
      font-size: 18px;

      .product-left {
        margin: 20px 50px;

        div:first-child {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 26px;
        }
      }

      .product-right {
        margin: 50px 80px 0 0;
        background: url(./../assets/btn_bg.png)no-repeat 0 0;
        background-size: 100%;
        width: 180px;
        height: 100px;
        padding-left: 45px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 15px;
      }

      .product-right:hover {
        cursor: pointer;
      }
    }

    .containter {
      padding: 20px;
      font-size: 16px;
      color: #D2D2D2;
      text-align: center;
      img{
        width: 80px;
        margin-top: 40px;
      }
      .title{
        font-size: 20px;
        color: #1595EF;
        margin: 20px auto 10px;
      }
    }
  }
}
</style>
